import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "position", "department", "division" ]

  connect() {

    this.toggleEmployeeField(
      this.data.has('roles') && (this.data.get('roles') === 'mz_employee' || this.data.get('roles') === 'hq_reviewer')
    )
    StimulusReflex.register(this)
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleEmployeeField(roleId === '3' || roleId === '4')
  }

  toggleEmployeeField(isEmployee) {
    if (isEmployee) {
      $(this.positionTarget).show()
      $(this.departmentTarget).show()
      $(this.divisionTarget).show()
    } else {
      $(this.positionTarget).hide()
      $(this.departmentTarget).hide()
      $(this.divisionTarget).hide()
    }
  }

  loadVocDivisions(event) {
    let id = $(event.target).val()

    this.stimulate('User#divisions_details', { serializeForm: false }, id)
  }
}
