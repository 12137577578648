import { Controller } from "stimulus"
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import $ from "jquery"

export default class extends Controller {
    connect() {
      let ctx = this.element.getContext('2d')


      let type = this.data.get('type') || 'pie'
      let labels = JSON.parse(this.data.get('labels')) || []
      let colors = JSON.parse(this.data.get('colors')) || []
      let data = JSON.parse(this.data.get('data')) || []
      let title = this.data.get('title')

      let datasets = [{
        data: data,
        backgroundColor: colors
      }]

      this.chart = new Chart(ctx, {
        plugins: [
          {
            id: 'savetopng',
            afterRender: function(chart, options) {
              let hidden = options.hidden
              if (hidden){
                $(hidden).val(chart.toBase64Image())
              }
            }
          },
          {
            id: 'centerpietext',
            beforeDraw: function(chart, args, options) {
              let total = options.total_value
              if (total) {
                let width = chart.chart.width;
                let height = chart.chart.height;
                let ctx = chart.chart.ctx;
                ctx.restore();
                let fontSize = (height / 400).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "middle";
                let text = total;
                let  textX = Math.round((width - ctx.measureText(text).width) / 2);
                let textY = height / 1.85;
                ctx.fillText(text, textX, textY);
                ctx.save();
              }
            }
          }
        ],
        type: type,
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          responsive: true,
          tooltips: {
            enabled: false
          },
          title: {
            display: true,
            text: title
          },
          plugins: {
            savetopng: {
              hidden: this.data.get('hidden')
            },
            centerpietext: {
              total_value: this.data.get('total-center-value')
            },
            datalabels: {
              display: function(context) {
                return context.dataset.data[context.dataIndex] !== 0;
              },
              color: 'black',
              font: {
                size: 20
              }
            }
          }
        }
      });
    }

  disconnect() {
    this.chart.destroy()
  }
}
