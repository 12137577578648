import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  static targets = [ "usersCount" ]

  connect() {
    this.toggleUsersCntField(
      this.data.has('logic-kind') && (this.data.get('logic-kind') === 'authorization_logic')
    )
    StimulusReflex.register(this)
  }
  loadDivisions(event) {
    let id = $(event.target).val()
    if (!id) { return }

    $('#gov-test-period-divisions-details select').attr('data-controller', '')

    this.stimulate('GovTest::Period#divisions_details', { serializeForm: false }, id)
  }

  doLogicKindSelect(event) {
    const kind = event.target.value
    this.toggleUsersCntField(kind === 'authorization_logic')
  }

  toggleUsersCntField(isAuth) {
    if (isAuth) {
      $(this.usersCountTarget).show()
    } else {
      $(this.usersCountTarget).hide()
    }
  }
}
