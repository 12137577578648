import { Controller } from "stimulus"
import $ from "jquery";


export default class extends Controller {
  static targets = [ "submitButton"]

  connect() {
    $('.test_request_question_answer_ids').find('legend').hide()
  }

  submitForm(e) {
    $(this.submitButtonTarget).click()
  }
}
